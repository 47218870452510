import React, { useState, useEffect, useRef } from "react";
import Summary from "./Summary";
import { useSelector } from "react-redux";
import Payment from "./Payment";
import axios from "axios";
import { Alert, CircularProgress } from "@mui/material";

import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import ReactHelmet from "../../Components/ReactHelmet/ReactHelmet";

const initialState = {
  name: "",
  email: "",
  phone: "",
  state: "",
  city: "",
  line1: "",
  line2: "",
  postal_code: "",
  payment_method: "",
  total_amount: 0,
  items: [],
};

const stripePK = process.env.REACT_APP_STRIPE_PK;

const stripePromise = loadStripe(stripePK);

const appearance = {
  theme: "stripe",
  variables: {
    colorPrimary: "#b6101d",
    borderRadius: "4px",
    // fontFamily:
    //   "--body-font-family: -apple-system, BlinkMacSystemFont, sans-serif",
    colorBackground: "#fafafa",
    focusOutline: "none",
    focusBoxShadow: "none",
  },
};

const Checkout = ({ user }) => {
  const [checkoutData, setCheckoutData] = useState(initialState);
  const [paymentMethod, setPaymentMethod] = useState("card");
  const [informationAmount, setInformationAmount] = useState({
    isFirstOrder: false,
    discount: 0,
    shipping: 0,
    subTotal: 0,
    total: 0,
  });
  const [isPaymentIntentLoading, setIsPaymentIntentLoading] = useState(false);
  const getCarddata = useSelector((state) => state.cardItems);

  const [paymentIntentClientSecret, setPaymentIntentClientSecret] = useState();
  const [paymentIntentId, setPaymentIntentId] = useState();

  const [error, setError] = useState(null);

  const isFirstRun = useRef(true);

  const handleCheckoutDataChange = (newData) => {
    setCheckoutData({ ...checkoutData, ...newData });
  };

  useEffect(() => {
    const getSession = async () => {
      setIsPaymentIntentLoading(true);
      if (getCarddata && getCarddata.cardData) {
        //   Variable to store total
        let res = 0;
        // Variable to store ordered Data
        let temp = [];
        for (let i = 0; i < getCarddata.cardData.length; i++) {
          res =
            res +
            getCarddata.cardData[i].quantity * getCarddata.cardData[i].price;

          temp.push({
            product_id: getCarddata.cardData[i].id,
            qty: getCarddata.cardData[i].quantity,
            price: getCarddata.cardData[i].price,
          });
        }

        setCheckoutData({
          ...checkoutData,
          items: temp,
        });
        let localStorageData = localStorage.getItem("user");
        let userForStripe;
        if (localStorageData) {
          userForStripe = JSON.parse(localStorageData);
        }
        await axios({
          method: "POST",
          url: `${process.env.REACT_APP_API_URL}/order/stripe-initiate`,
          data: {
            items: temp,
            user_id: userForStripe?.user?.id || null,
            stripe_cus_id: userForStripe?.user?.stripe_cus_id || null,
          },
        })
          .then((res) => {
            return res.data.data;
          })
          .then(
            ({
              client_secret,
              pid,
              is_first_order,
              discount,
              shipping,
              sub_total,
              total,
            }) => {
              setPaymentIntentId(pid);
              setPaymentIntentClientSecret(client_secret);
              setInformationAmount({
                isFirstOrder: is_first_order,
                discount,
                shipping,
                subTotal: sub_total,
                total,
              });
              setIsPaymentIntentLoading(false);
            },
          )
          .catch((e) => {
            console.error(e);
            setIsPaymentIntentLoading(false);
          });
      }
    };
    if (isFirstRun.current) {
      getSession();
      isFirstRun.current = false;
    }
  }, [getCarddata]);

  if (isPaymentIntentLoading) {
    return (
      <div className="bg-white">
        <div className="container flex items-center justify-center h-[500px]">
          <CircularProgress />
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-4 py-8 bg-white">
      <div className="relative container flex flex-col-reverse lg:flex-row gap-12">
        <div className="flex flex-col gap-4 w-full lg:w-1/2">
          <div className="text-center text-p text-[26px] font-bold">
            <ReactHelmet title="Checkout - Nepali Garden" />
            <p>
              Billing / <span className="text-green">Checkout</span>
            </p>
          </div>
          <div className="flex flex-col gap-4 w-full checkout-section rounded-md h-fit !m-0 [&_label]:font-semibold min-h-[500px] [&_label]:tracking-wide !bg-[#F7F7F7]">
            {error ? (
              <Alert severity="error" className="mb-4">
                {error}
              </Alert>
            ) : null}

            <span className="text-black text-sm">
              Please verify order summary before paying for your order.
            </span>

            {paymentIntentClientSecret && (
              <Elements
                stripe={stripePromise}
                options={{
                  clientSecret: paymentIntentClientSecret,
                  appearance,
                }}
              >
                <Payment
                  setPaymentMethod={setPaymentMethod}
                  paymentMethod={paymentMethod}
                  handleCheckoutDataChange={handleCheckoutDataChange}
                  checkoutData={checkoutData}
                  setError={setError}
                  paymentIntentId={paymentIntentId}
                  user={user}
                  informationAmount={informationAmount}
                />
              </Elements>
            )}

            <span className="text-gray-500 text-sm italic font-semibold">
              Please note: If you do not receive an order confirmation email
              within 24 hours, kindly reach out to us at
              nepaligardenwholesale@gmail.com.
            </span>
          </div>
        </div>

        <div className="lg:absolute w-full lg:w-[45%] h-full lg:right-4 lg:overflow-hidden">
          <Summary
            user={user}
            items={getCarddata?.cardData}
            informationAmount={informationAmount}
          />
        </div>
      </div>
    </div>
  );
};

export default Checkout;
